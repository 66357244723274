
import KachelContainer from '@/components/KachelContainer.vue';
import { defineComponent, ref } from 'vue';

interface Data{
    temperatur: number;
    heatings: string[];
}
interface Gruppe{
  style?: {
    backgroundColor?: string;
    color?: string;
    contentColor?: string;
  };
  showNormalSize: string;
  titel: string;
  data: Data;
}

export default defineComponent({
  components: {
    KachelContainer,
  },
  name: 'HomeView',
  setup() {
    const gruppen = ref<Gruppe[]>([
      {
        style: {
          backgroundColor: '#ff9721',
          color: '#fbecdb',
          contentColor: '#fcbf79',
        },
        showNormalSize: 'normal',
        titel: 'Kinder + Arbeitszimmer',
        data: {
          heatings: [],
          temperatur: 45,
        },
      },
      {
        style: {
          backgroundColor: '#ff9721',
          color: '#fbecdb',
          contentColor: '#fcbf79',
        },
        showNormalSize: 'normal',
        titel: 'NebenStellen',
        data: {
          heatings: [],
          temperatur: 45,
        },
      },
      {
        style: {
          backgroundColor: '#ff9721',
          color: '#fbecdb',
          contentColor: '#fcbf79',
        },
        showNormalSize: 'normal',
        titel: 'Wohnraum',
        data: {
          heatings: [],
          temperatur: 45,
        },
      },
      {
        style: {
          backgroundColor: '#ff9721',
          color: '#fbecdb',
          contentColor: '#fcbf79',
        },
        showNormalSize: 'normal',
        titel: 'Alle',
        data: {
          heatings: [],
          temperatur: 45,
        },
      },
    ]);

    const setzenDerHeizungsWerte = (data: Data) => {
      console.log(data);
    };
    const setzenHeizungenAufZeitplan = (data: Data) => {
      console.log(data);
    };
    return { gruppen, setzenDerHeizungsWerte, setzenHeizungenAufZeitplan };
  },
});
