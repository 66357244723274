
import { computed, defineComponent, ref } from 'vue';
import {
  SettingFilled,
  SyncOutlined,
  CloseOutlined,
  SaveOutlined,
  CalendarOutlined,
  LoadingOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    SettingFilled,
    SyncOutlined,
    SaveOutlined,
    CalendarOutlined,
    CloseOutlined,
    LoadingOutlined,
  },
  props: {
    style: {
      type: Object,
      default: () => ({
        backgroundColor: '#ff9721',
        color: '#fbecdb',
        contentColor: '#fcbf79',
      }),
    },
    titel: {
      type: String,
      default: 'GruppenName',
    },
    data: {
      type: Object,
      default: () => ({
        temperatur: 28,
      }),
    },
  },
  setup(props, { emit }) {
    const isEdit = ref<boolean>(false);

    const temperaturData = ref<number>(props.data.temperatur);
    const temperaturDataTemp = ref<number>(props.data.temperatur);

    const title = computed<string>(() => props.titel);
    const generelStyle = (position:string):string | undefined => {
      if (position === 'card') {
        return `background-color:${props.style.backgroundColor}; color:${props.style.color};`;
      }
      if (position === 'content') {
        return `color:${props.style.contentColor};`;
      }
      return undefined;
    };
    const toggleEdit = (checkenAufAltenWert = '') => {
      if (checkenAufAltenWert === 'alterWert') {
        temperaturData.value = temperaturDataTemp.value + 0;
      }
      temperaturDataTemp.value = temperaturData.value + 0;
      isEdit.value = !isEdit.value;
    };
    const save = () => {
      emit('heatingSetzen', { ...props.data, temperatur: temperaturData.value });
      toggleEdit();
    };
    const plan = () => {
      emit('planSetzen', { ...props.data, temperatur: temperaturData.value });
      toggleEdit();
    };

    return {
      title,
      isEdit,
      save,
      plan,
      generelStyle,
      toggleEdit,
      temperaturData,
    };
  },
});
