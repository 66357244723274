import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9a3bcee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kachel" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "content-temperatur" }
const _hoisted_4 = {
  key: 1,
  class: "temperatur"
}
const _hoisted_5 = {
  key: 0,
  class: "content-slider"
}
const _hoisted_6 = { class: "content-temperatur schmuck" }
const _hoisted_7 = {
  key: 1,
  class: "temperatur"
}
const _hoisted_8 = { class: "content-leiste" }
const _hoisted_9 = { class: "content-gruppe" }
const _hoisted_10 = { class: "content-edit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_outlined = _resolveComponent("loading-outlined")!
  const _component_sync_outlined = _resolveComponent("sync-outlined")!
  const _component_a_slider = _resolveComponent("a-slider")!
  const _component_setting_filled = _resolveComponent("setting-filled")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_calendar_outlined = _resolveComponent("calendar-outlined")!
  const _component_save_outlined = _resolveComponent("save-outlined")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, {
      hoverable: "",
      style: _normalizeStyle([{"width":"100%","height":"100%"}, _ctx.generelStyle('card')])
    }, {
      cover: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.temperaturData === -1)
              ? (_openBlock(), _createBlock(_component_loading_outlined, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.temperaturData) + "°C", 1))
          ]),
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_sync_outlined, { spin: "" }),
                _createVNode(_component_a_slider, {
                  id: "test",
                  max: 25,
                  value: _ctx.temperaturData,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.temperaturData) = $event))
                }, null, 8, ["value"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.temperaturData === -1)
              ? (_openBlock(), _createBlock(_component_loading_outlined, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.temperaturData) + "°C", 1))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h1", {
                style: _normalizeStyle(_ctx.generelStyle('content'))
              }, _toDisplayString(_ctx.title), 5)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (!_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_setting_filled, {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleEdit())),
                    class: "setting",
                    color: _ctx.generelStyle('content')
                  }, null, 8, ["color"]))
                : _createCommentVNode("", true),
              (_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_close_outlined, {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleEdit('alterWert'))),
                    color: _ctx.generelStyle('content')
                  }, null, 8, ["color"]))
                : _createCommentVNode("", true),
              (_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_calendar_outlined, {
                    key: 2,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.plan())),
                    color: _ctx.generelStyle('content')
                  }, null, 8, ["color"]))
                : _createCommentVNode("", true),
              (_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_save_outlined, {
                    key: 3,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.save())),
                    color: _ctx.generelStyle('content')
                  }, null, 8, ["color"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["style"])
  ]))
}